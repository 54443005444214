import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as showDialog from '@/utils/showDialog';
import Loading from '../Layout/handler/Loading';
import {
  clearResult,
  getIssueFromCodeResultsSelector,
  getIssueFromCodeQuery,
  isLoadingIssueFromCodeSelector,
} from '@/store/slices/getIssueFromCodeSlice';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';

interface Props {
  title: string | null;
}

export default function UseCoupon({ title }: Props) {
  const { t, i18n } = useTranslation();
  const { t: t_product } = useTranslation('product');

  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [isClick, setIsClick] = useState(false);
  const router = useRouter();
  const getIssueFromCodeResults = useSelector(getIssueFromCodeResultsSelector);
  const isLoadingIssueFromCode = useSelector(isLoadingIssueFromCodeSelector);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsClick(true);
    if (inputValue && inputValue !== '') {
      dispatch(
        getIssueFromCodeQuery({ lang: i18n.language, code: inputValue }),
      );
    } else {
      setIsClick(false);
      showDialog.showErrorWithOkButton({
        text: t('pleaseenterthecode'),
      });
    }
  };

  useEffect(() => {
    if (!isLoadingIssueFromCode && isClick) {
      if (getIssueFromCodeResults.status === 401) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            image: '/images/svg/success_circle.svg',
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResult());
          });
      } else {
        if (getIssueFromCodeResults.iserror == false) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t('close'),
              },
            })
            .then(() => {
              dispatch(clearResult());
            });
        } else {
          showDialog
            .showErrorWithOkButton({
              text: `${getIssueFromCodeResults.msg}`,
            })
            .then(() => {
              dispatch(clearResult());
            });
        }
      }
      setIsClick(false);
    }
  }, [
    dispatch,
    isLoadingIssueFromCode,
    getIssueFromCodeResults,
    router,
    t,
    t_product,
    isClick,
  ]);
  return (
    <div className="my-2 text-center">
      {isClick ? <Loading /> : ''}
      <div className="font-bold text-xl"> {title ? title : <></>}</div>
      <div className="flex justify-center flex-col lg:flex-row gap-2 lg:gap-0">
        <input
          placeholder={`${t('pleaseputcode')}`}
          type="text"
          className="bg-white border border-[#dadada] rounded lg:rounded-none lg:rounded-l py-0.5 px-2.5 w-full lg:w-1/3"
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          disabled={isClick}
          className={`bg-black hover:bg-black text-white font-bold mx-auto lg:mx-0 lg:rounded-r lg:rounded-l-none w-1/2 lg:w-auto`}
          sx={{
            backgroundColor: '#000 !important',
            color: 'white',
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: 'black',
            },
            borderRadius: {
              lg: '0px 5px 5px 0px !important',
              xs: '5px !important',
            },
            width: {
              lg: 'auto',
              xs: '50%',
            },
            mx: {
              xs: 'auto',
              lg: 0,
            },
          }}
          onClick={handleSubmit}
        >
          <span className={`${isClick ? 'text-white' : 'text-white'}`}>
            {t('submitcoupon')}
          </span>
        </Button>
      </div>
    </div>
  );
}
