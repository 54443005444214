import { Content } from '@/models/CMS.model';
import { ProductModel } from '@/models/Product.model';
import React, { useEffect, useMemo, useState } from 'react';
import SlickProductCard from '../common/SlickProductCard';
import { Grid, Skeleton } from '@mui/material';
import ProductCard from '../ProductCard';
declare const imageMapResize: any;
interface Props {
  data: Content;
}
export default function Default({ data }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const slickComponentsSlide: JSX.Element[] = useMemo(() => [], []); // Initialize as empty array
  const slickComponents: JSX.Element[] = useMemo(() => [], []);
  const isSlide = data.isSlide ? data.isSlide : false;
  const color = data.color ? data.color : null;
  const colorSecond = data.colorSecond ? data.colorSecond : null;
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/imageMapResizer.min.js';
    script.async = true;
    script.onload = () => {
      const targetElement = document.querySelector('img[usemap]');
      if (targetElement && typeof imageMapResize === 'function') {
        imageMapResize();
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
  useEffect(() => {
    if (data && data.productskus && data.productskus.length > 0) {
      setIsLoading(false);
    }
  }, [
    data,
    isSlide,
    slickComponents,
    slickComponentsSlide,
    color,
    colorSecond,
  ]);
  return (
    <>
      {isLoading ? (
        <>
          <Grid container>
            <Grid item xs={6} lg={3} className="px-2 my-2">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item xs={6} lg={3} className="px-2 my-2">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
            <Grid item lg={3} className="px-2 my-2 lg:block xs:hidden">
              <Skeleton
                variant="rounded"
                style={{ width: '100%', height: '300px' }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {isSlide ? (
            <div
              className="py-1"
              style={{ backgroundColor: color ? color : '' }}
            >
              <SlickProductCard
                arrows
                products={data.productskus}
                autoplay={true}
                bgColor={colorSecond}
                slidesToShow={4}
                infinite={
                  (data.productskus?.length ?? 0) > 1 ? undefined : false
                }
                xs={{ slidesToShow: 2 }}
                sm={{ slidesToShow: 2 }}
                md={{ slidesToShow: 2 }}
                lg={{ slidesToShow: 4 }}
                isShowAddToCart={true}
                listName={`static_page`}
              />
            </div>
          ) : (
            <Grid
              container
              className=""
              style={{ backgroundColor: color ? color : '' }}
            >
              {data.productskus &&
                data.productskus.map((element: ProductModel, index: number) => (
                  <Grid
                    key={`${index}`}
                    item
                    xs={6}
                    lg={3}
                    className="p-1"
                    style={{
                      backgroundColor: colorSecond ? colorSecond : '',
                    }}
                  >
                    <ProductCard product={element} />
                  </Grid>
                ))}
            </Grid>
          )}
        </>
      )}
    </>
  );
}
