import React from 'react';
import Image from 'next/image';
import { Article } from '@/models/HomePage.model';
import { useTranslation } from 'next-i18next';
import AppLink from '../Layout/handler/AppLink';
import SliderLayout from './SliderLayout';
import Button from '@mui/material/Button';

export default function ArticleCat({
  content,
}: {
  content: Article[] | undefined;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="font-semibold text-xl lg:text-3xl border-b border-grayLight3">
        {t('common:knowledgewiththaiwatsadu')}
      </div>

      <SliderLayout
        infinite={false}
        dots={false}
        arrows={true}
        autoplay={true}
        slidesToShow={2.5}
        xs={{ slidesToShow: 1.25 }}
        sm={{ slidesToShow: 1.5 }}
        md={{ slidesToShow: 2.25 }}
        lg={{ slidesToShow: 2.5 }}
        fullHeight
      >
        {content &&
          content.map((article, index) => (
            <div
              className={`h-full ${
                index === 0
                  ? 'pe-0.5 sm:pe-1'
                  : index === content.length - 1
                  ? 'ps-0.5 sm:ps-1'
                  : 'px-0.5 sm:px-1'
              } `}
              key={index}
            >
              <div className="relative my-2 p-2 bg-white border rounded m-0 h-full flex flex-col">
                <div className="flex items-cente my-auto">
                  <Image
                    src={article.img ?? ''}
                    alt={article.title ?? ''}
                    width={1380}
                    height={600}
                  />
                </div>
                <div className="relative">
                  <div className="bg-white mb-5 lg:mb-0">
                    <div
                      className="pt-1 text-base lg:text-xl font-semibold leading-5"
                      style={{
                        display: '-webkit-box',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                    >
                      {article.subject}
                    </div>
                    <div
                      className="text-sm lg:text-base leading-5 line-clamp-2 md:line-clamp-3"
                      style={{
                        display: '-webkit-box',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        // WebkitLineClamp: 3,
                      }}
                    >
                      {article.title}
                    </div>
                  </div>
                  <div className="right-2 bottom-0 z-10 text-primary cursor-pointer">
                    <AppLink href={`/articles/${article.slug}`}>
                      <Button
                        size="small"
                        className="hover:opacity-90"
                        style={{
                          fontSize: '1rem',
                          backgroundColor: '#B8292F',
                          color: '#FFFFFF',
                          paddingLeft: 12,
                          paddingRight: 12,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                      >
                        {t('common:continuereading')}
                      </Button>

                      {/* <i
                          className="bi bi-arrow-right mt-2"
                          style={{ verticalAlign: '-.05em' }}
                        /> */}
                    </AppLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </SliderLayout>
    </div>
  );
}
