import { Grid } from '@mui/material';
import React from 'react';
import styles from '../../styles/bundle.module.css';
import SaleBadge from '../ProductCard/badge/SaleBadge';
import SlickProductCard from '../common/SlickProductCard';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import AddToCartButton from '../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';
import { ProductModel } from '@/models/Product.model';

interface Props {
  index: string;
  element: any;
  convertedProducts: ProductModel[];
  allorgprice: string;
  allplppprice: string;
  setid: string;
  skuQtyList?: SkuWithQty[];
  isSlide?: Boolean;
}
export default function BundleImageCard({
  index,
  element,
  convertedProducts,
  allorgprice,
  allplppprice,
  setid,
  skuQtyList,
  isSlide,
}: Props) {
  const { t } = useTranslation('product');
  // const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <Grid key={`${index}`} item xs={12} lg={isSlide ? 12 : 4}>
      <div
        className="lg:mx-2 my-2"
        style={{
          borderRadius: '5px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {element.keyword && element.isLine == '1' ? (
          <div className={`${styles.cornerribbon} bg-primary text-white`}>
            {element.keyword}
          </div>
        ) : (
          <></>
        )}
        {element &&
        element.bundlesku &&
        element.bundlesku[0] &&
        Array.isArray(element.bundlesku[0]) &&
        element.bundlesku[0][0].allpercent ? (
          <div className="absolute top-2 right-2">
            <SaleBadge
              discountPercent={Number(element.bundlesku[0][0].allpercent)}
            />
          </div>
        ) : (
          <></>
        )}
        <Grid
          container
          // justifyItems={'center'}
          // justifyContent={'center'}
          sx={{ background: 'white' }}
          className="bg-white bundleList"
        >
          <Grid item xs={12} lg={12} className="">
            <Image
              src={element.imgBundle}
              alt="Bundle Image"
              width={600}
              height={500}
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={12} lg={12} className="">
            <SlickProductCard
              arrows
              products={convertedProducts}
              listName={`bundle_image`}
              isBundle={true}
              isBundleImage={true}
              infinite={false}
              dots={false}
              slidesToShow={2}
            />
          </Grid>
          <Grid item xs={11} lg={11} className="border-t"></Grid>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2} className="px-2 py-1">
              <Grid item xs={7} lg={7} className="text-center leading-5">
                <div className="flex justify-end">
                  <span className="text-xs pr-2 mr-2 lg:mr-5 text-[#999] my-auto line-through">
                    ฿{allorgprice}
                  </span>
                  <span className="text-redPrice text-sm mr-2 lg:mr-4">
                    {t('onlyleft')}
                  </span>
                </div>
                <div className="flex justify-end -mt-1.5">
                  <span className="pr-1 text-redPrice my-auto">฿</span>
                  <span>
                    <span className="text-redPrice pr-1 text-lg lg:text-2xl leading-5 font-price">
                      {allplppprice}
                    </span>
                    <span className="text-xs text-grayDark my-auto">/SET</span>
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={5}
                lg={5}
                className="m-auto"
                style={{ margin: 'auto' }}
              >
                <div className="flex justify-center">
                  <AddToCartButton
                    setId={setid}
                    skuQtyList={skuQtyList}
                    isOutOfStock={false}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
