import React from 'react';
import BundleSection from '../ProductDetailPage/BundleSection';

interface Props {
  data: any;
  isFromCms?: boolean;
}

export default function BundlePage({ data, isFromCms }: Props) {
  const datas = {
    products: data,
  };
  return (
    <>{datas ? <BundleSection data={datas} isFromCms={isFromCms} /> : <></>}</>
  );
}
