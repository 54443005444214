import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

export default function SeoCMS({ content }: { content?: string }) {
  const [isReadMore, setIsReadMore] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {content && (
        <div
          className={`p-3 mb-5 overflow-hidden rounded-lg relative bg-white leading-5 ${
            isReadMore ? 'h-full' : 'h-40'
          }`}
        >
          <div
            className="mb-2 htmlContent"
            dangerouslySetInnerHTML={{
              __html:
                content
                  ?.toString()
                  ?.replaceAll('<h2>', '<h2 class="text-2xl">')
                  ?.replaceAll('<h3>', '<h3 class="text-xl">')
                  ?.replaceAll(
                    '<h4>',
                    '<h4 class="text-lg" style="font-weight:600">',
                  ) || '',
            }}
          />

          {!isReadMore && (
            <div
              className="absolute text-center bottom-0 left-0 z-10 w-full"
              style={{
                padding: '3rem 0',
                backgroundImage:
                  'linear-gradient(to bottom,rgba(255,255,255,.3),#fff)',
              }}
            ></div>
          )}
          <div
            className="absolute text-center bottom-0 left-0 z-10 w-full text-primary cursor-pointer"
            onClick={handleReadMore}
          >
            {isReadMore ? (
              <span>
                {t('readless')}
                <i className="bi bi-arrow-up-short" />
              </span>
            ) : (
              <span>
                {t('readmore')}
                <i className="bi bi-arrow-down-short" />
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
}
