import React from 'react';

interface Props {
  countDown: number;
  isHours?: boolean;
}

export default function CountDown({ countDown, isHours }: Props) {
  const hour = isHours
    ? Math.floor((countDown ?? 0) / (1000 * 60 * 60))
    : Math.floor((countDown ?? 0) / (1000 * 60 * 60)) % 24;

  const minute = Math.floor(
    ((countDown ?? 0) % (1000 * 60 * 60)) / (1000 * 60),
  );

  const second = Math.floor(((countDown ?? 0) % (1000 * 60)) / 1000);

  return (
    <div className="flex justify-center items-center font-bold text-primary text-sm sm:text-base md:text-lg">
      <MapDigitToBox time={hour} />:<MapDigitToBox time={minute} />:
      <MapDigitToBox time={second} />
    </div>
  );
}

const MapDigitToBox = ({ time }: { time: number }) => {
  let timeString = time.toString();
  if (timeString.length <= 1) {
    timeString = '0' + timeString;
  }
  return (
    <>
      {[...Array(2)].map((_, i) => (
        <div className="px-0.5" key={i}>
          <div className="bg-white p-1 rounded flex w-4 h-4 sm:w-5 sm:h-5 xl:w-6 xl:h-6 items-center justify-center">
            {timeString[i]}
          </div>
        </div>
      ))}
    </>
  );
};
