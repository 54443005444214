import { ProductSlide } from '@/models/HomePage.model';
import React, { useEffect, useState } from 'react';
import { useTranslation as translation } from 'next-i18next';
import SlickProductCard from '../../common/SlickProductCard';
import { Button, Grid } from '@mui/material';
import Image from 'next/image';
import { getEventData } from '@/services/client/getEventDataService';
import { ProductModel } from '@/models/Product.model';
import CountDown from './CountDown';
import AppLink from '@/components/Layout/handler/AppLink';

export default function FlashSale({
  flashsale,
  products,
}: {
  flashsale?: ProductSlide;
  products?: ProductModel[];
}) {
  const { t, i18n } = translation();
  const [flashSaleData, setFlashSaleData] = useState<ProductModel[]>();
  const [endTime, setEndTime] = useState<number | undefined>(undefined);
  const [countDown, setCountDown] = useState<number | undefined>(undefined);

  const calFlashSaleTime = (data: ProductModel[]) => {
    const now = new Date();
    const nowTime = now.getTime();
    const startDate = data[0]?.startDate
      ? new Date(data[0].startDate)
      : undefined;
    const endDate = data[0]?.endDate ? new Date(data[0].endDate) : undefined;
    if (startDate && endDate) {
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      if (nowTime > startTime && endTime > nowTime) {
        setEndTime(endTime);
      } else {
        setEndTime(undefined);
      }
    }
  };

  useEffect(() => {
    if (flashsale?.type && !products) {
      getEventData({
        lang: i18n.language,
        hotcat: flashsale?.type,
        maxshow: flashsale?.showmax,
      }).then((res) => {
        const data = res && res.products ? res.products : [];
        setFlashSaleData(data);
        calFlashSaleTime(data);
      });
    } else if (products && products.length > 0) {
      setFlashSaleData(products);
      calFlashSaleTime(products);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flashsale?.type, i18n.language]);

  useEffect(() => {
    const now = new Date();
    const nowTime = now.getTime();
    if (endTime && endTime > nowTime) {
      const interval = setInterval(() => {
        setCountDown(endTime - nowTime);
      }, 500);
      return () => clearInterval(interval);
    } else {
      setCountDown(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, countDown]);

  const buttonSeeMore = (
    <AppLink href={`/event/${flashsale?.type}`}>
      <Button
        size="small"
        className="hover:opacity-90"
        style={{
          fontSize: '1rem',
          backgroundColor: '#000000',
          color: '#FFFFFF',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {t('viewallproducts')}
      </Button>
    </AppLink>
  );

  return (
    <>
      {flashSaleData && flashSaleData.length > 0 && countDown ? (
        <div id="flash-sale">
          <Grid container style={{ backgroundImage: flashsale?.img }}>
            <Grid
              item
              xs={12}
              lg={3}
              className="text-center"
              style={{
                margin: 'auto',
              }}
            >
              <div className="flex lg:block items-center justify-evenly px-1">
                <div className="flex justify-center">
                  <Image
                    src="/images/homepage/twdflashchar.png"
                    alt="flash-sale-image"
                    className="max-h-[4rem] lg:max-h-none w-auto"
                    id="flash-sale-image"
                    width={282}
                    height={136}
                  />
                </div>
                <div className="pe-0.5 sm:pe-0">
                  <div className="text-[#FF0000] text-right md:text-center font-bold text-base sm:text-lg md:text-2xl h-4 sm:h-6 lg:h-auto pe-0.5 sm:pe-0">
                    {t('onlytimeleft')}
                  </div>
                  <div>
                    <CountDown countDown={countDown} isHours />
                  </div>
                  <div className="mt-2 hidden lg:block">{buttonSeeMore}</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <div className="px-1">
                <SlickProductCard
                  arrows
                  products={flashSaleData}
                  autoplay={true}
                  slidesToShow={3}
                  isFlashSale={true}
                  xs={{ slidesToShow: 2 }}
                  sm={{ slidesToShow: 2 }}
                  md={{ slidesToShow: 2 }}
                  lg={{ slidesToShow: 3 }}
                  listName={`flash_sale`}
                />
              </div>
              <div className="mt-1 mb-2 text-center block lg:hidden">
                {buttonSeeMore}
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
