import { Content } from '@/models/CMS.model';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CouponCard from './CouponCard';
import { Box, Grid, Skeleton } from '@mui/material';
import { Reward } from '@/models/profile/MyVoucherDetail.model';
import styles from '../../styles/coupon.module.css';
import Slider from 'react-slick';

interface Props {
  data: Content;
}
function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer `}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: '-20px !important',
          top: '40%',
          transform: 'translateY(-50%)',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-right"></i>
        </Box>
      </Box>
    </>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <>
      <Box
        className={`bg-white shadow-lg cursor-pointer`}
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: '40%',
          transform: 'translateY(-50%)',
          left: '-20px !important',
        }}
        onClick={onClick}
      >
        <Box
          className="h-6 w-6 sm:h-8 sm:w-8 p-1 sm:p-2"
          sx={{ lineHeight: '1rem' }}
        >
          <i className="bi bi-chevron-left"></i>
        </Box>
      </Box>
    </>
  );
}
export default function Coupon({ data }: Props) {
  const [isLoading, setIsLoading] = useState(true);
  const sliderRef = useRef<Slider>(null);
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
          dotsClass: `slick-dots ${styles.dots}`,
        },
      },
    ],
  };
  const slickComponentsSlide: JSX.Element[] = useMemo(() => [], []); // Initialize as empty array
  const slickComponents: JSX.Element[] = useMemo(() => [], []);
  const detailCoupon = data.detailCoupon;
  const isSlide = data.isSlide ? data.isSlide : false;

  useEffect(() => {
    if (detailCoupon && detailCoupon.length > 0) {
      // Clear the slickComponents array before adding new elements
      slickComponents.length = 0;
      slickComponentsSlide.length = 0;
      if (isSlide) {
        slickComponentsSlide.push(
          ...detailCoupon.map((element: Reward, index: number) => (
            <div key={index} className="p-2">
              <CouponCard data={element} />
            </div>
          )),
        );
      } else {
        slickComponents.push(
          <Grid container key="grid-container">
            {detailCoupon.map((element: Reward, index: number) => (
              <Grid
                key={`${index}`}
                item
                xs={12}
                md={6}
                lg={4}
                className="lg:p-2 xs:p-1"
              >
                <CouponCard data={element} />
              </Grid>
            ))}
          </Grid>,
        );
      }
      setIsLoading(false);
    }
  }, [data, isSlide, slickComponents, slickComponentsSlide, detailCoupon]);

  return (
    <>
      {isLoading ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6} lg={4} className="lg:p-2 xs:p-1">
              <Skeleton
                className={`${styles.couponwrapSkeleton}`}
                variant="rounded"
                // style={{ width: '100%', height: '200px' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="lg:p-2 xs:p-1">
              <Skeleton
                className={`${styles.couponwrapSkeleton}`}
                variant="rounded"
                // style={{ width: '100%', height: '200px' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} className="lg:p-2 xs:p-1">
              <Skeleton
                className={`${styles.couponwrapSkeleton}`}
                variant="rounded"
                // style={{ width: '100%', height: '200px' }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div className="mt-2">{slickComponents}</div>
          <Slider ref={sliderRef} {...settings}>
            {slickComponentsSlide}
          </Slider>
        </>
      )}
    </>
  );
}
