import { Reward } from '@/models/profile/MyVoucherDetail.model';
import React, { useState, useEffect } from 'react';
import styles from '../../styles/coupon.module.css';
import Image from 'next/image';
import { Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useAppDispatch } from '@/store/store';
import {
  clearResultGet1010,
  get1010CouponQuery,
  get1010CouponResultsSelector,
  isLoading1010CouponSelector,
} from '@/store/slices/get1010CouponSlice';
import { useSelector } from 'react-redux';
import * as showDialog from '@/utils/showDialog';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';

interface Props {
  data: Reward;
}
export default function CouponCard({ data }: Props) {
  const { t, i18n } = useTranslation('');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isClickGet1010coupon, setIsClickGet1010coupon] = useState(false);
  const get1010CouponResults = useSelector(get1010CouponResultsSelector);
  const isLoading1010Coupon = useSelector(isLoading1010CouponSelector);
  const getCoupon = (id: string) => {
    dispatch(
      get1010CouponQuery({
        lang: i18n.language,
        rewardid: id,
      }),
    );
    setIsClickGet1010coupon(true);
  };

  useEffect(() => {
    if (!isLoading1010Coupon && isClickGet1010coupon) {
      if (get1010CouponResults.status === 401) {
        showDialog
          .showMessageWithButton({
            text: t('pleaseloginbeforegetcode'),
            primaryButton: {
              name: t('signin'),
              callBack: () => {
                router.push(
                  `/${router.locale}/signin${getRedirectQuery(router.asPath)}`,
                );
              },
            },
            secondaryButton: {
              name: t('cancel'),
            },
          })
          .then(() => {
            dispatch(clearResultGet1010());
          });
      } else {
        if (get1010CouponResults.iserror == false) {
          showDialog
            .showMessageWithButton({
              text: t('congratulationsyouhavecoupon'),
              image: '/images/svg/success_circle.svg',
              primaryButton: {
                name: t('viewcoupon'),
                callBack: () => {
                  router.push(`/${router.locale}/profile/myvoucher`);
                },
              },
              secondaryButton: {
                name: t('close'),
              },
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        } else {
          showDialog
            .showErrorWithOkButton({
              text: `${get1010CouponResults.msg}`,
            })
            .then(() => {
              dispatch(clearResultGet1010());
            });
        }
      }
      setIsClickGet1010coupon(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isLoading1010Coupon,
    get1010CouponResults,
    t,
    isClickGet1010coupon,
  ]);

  return (
    <div className={`${styles.couponwrap} bg-primaryPink`}>
      <div className="p-[16px] lg:p-[23px] my-auto">
        <div className="w-[88px] h-[88px] lg:w-[110px] lg:h-[110px]">
          <Image
            src={data.image ? data.image : '/images/svg/no-img.svg'}
            alt="logo"
            className="rounded-lg"
            width={110}
            height={110}
          />
        </div>
      </div>
      <div className="-mt-1.5">
        {[...Array(12)].map((_, index) => (
          <div
            key={index}
            className="w-[8px] h-[8px] bg-background rounded-full my-1"
          ></div>
        ))}
      </div>
      <div className="px-[10px] pb-[12px] text-primary w-full">
        <span className="">
          <span className="pr-2 text-2xl font-bold whitespace-nowrap">
            {t('usevoucher:voucher')}
          </span>
          <span className="f-kitti75 couponprice">
            <span className="text-md lg:text-xl pr-1 font-bold">
              {data.couponUnit == '฿' ? data.couponUnit : ''}
            </span>
            <span className="text-2xl lg:text-3xl font-bold">
              {data.couponAmount ? data.couponAmount : ''}
            </span>
            <span className="text-2xl lg:text-3xl">
              {data.couponUnit == '%' ? data.couponUnit : '.-'}
            </span>
          </span>
        </span>
        <div className="leading-[0.5rem] lg:leading-3 h-[1.5rem] lg:h-[2rem] pr-2 text-grayDark3 text-[15px] lg:text-[18px] line-clamp-3">
          {data.displayName}
        </div>
        <div className="flex leading-3">
          <div>
            <div
              className="leading-[0.5rem] lg:leading-3 mt-[0.20rem] lg:mt-[0.25rem] text-grayDark3 text-[15px] lg:text-[18px]"
              // style={{ lineHeight: '18px' }}
            >
              {data.id}
            </div>
            <div
              className={`leading-[0.5rem] lg:leading-3 ${styles.date} text-[15px] lg:text-[18px] text-grayDark3 mt-[0.20rem] lg:mt-[0.25rem] whitespace-nowrap`}
              // style={{ lineHeight: '18px' }}
            >
              <span className="me-1">{t('usevoucher:availabledate')}</span>{' '}
              {data.startDateShow} {data.startMonthShow}
              {data.startYearShow !== data.endYearShow
                ? data.startYearShow
                  ? (data.startYearShow + 543).toString().slice(-2)
                  : ''
                : ''}
              -{data.endDateShow} {data.endMonthShow}
              {data.endYearShow
                ? (data.endYearShow + 543).toString().slice(-2)
                : ''}
            </div>
          </div>
          <div className="ml-auto mt-auto">
            <Button
              variant="outlined"
              className="bg-primary"
              sx={{
                color: 'white',
                backgroundColor: '#B8292F',
                borderColor: '#B8292F',
                fontSize: { lg: '21px', xs: '18px' },
                minWidth: 0,
                padding: {
                  xs: '7px 16px',
                  lg: '5.5px 21px',
                },
                fontWeight: 700,
                borderRadius: '6px',
                lineHeight: '21px !important',
                '&:hover': {
                  background: '#B8292F',
                },
              }}
              onClick={() => {
                getCoupon(`${data.id}`);
              }}
            >
              {t('usevoucher:getCoupon')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
