import { Content } from '@/models/CMS.model';
import React, { useEffect } from 'react';
declare const imageMapResize: any;
interface Props {
  data: Content;
}
export default function DefaultContent({ data }: Props) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '/assets/js/imageMapResizer.min.js';
    script.async = true;
    script.onload = () => {
      const targetElement = document.querySelector('img[usemap]');
      if (targetElement && typeof imageMapResize === 'function') {
        imageMapResize();
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  return (
    <div
      className="leading-5 htmlContent"
      dangerouslySetInnerHTML={{
        __html:
          data.detailDefault
            ?.toString()
            ?.replaceAll('d-flex', 'flex')
            ?.replaceAll('btn', 'p-2')
            ?.replaceAll('h2topic', 'font-bold')
            ?.replaceAll(
              'articletitle',
              'articletitle line-clamp-4 mt-2 mb-4',
            ) ?? '',
      }}
    />
  );
}
